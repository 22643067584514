<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll-nav ref="nav" current="基本信息" v-if="detail">
        <cube-scroll-nav-panel key="基本信息" label="基本信息">
          <inputItem title="申请单号" :initialContent="detail.code" :disabled="true"></inputItem>
          <inputItem title="供货单位" :initialContent="detail.owner" :disabled="true"></inputItem>
          <inputItem title="客户名称" :initialContent="detail.invoice_title" :disabled="true"></inputItem>
          <inputItem title="识别号码" :initialContent="detail.invoice_identify" :disabled="true"></inputItem>
          <inputItem title="联系地址" :initialContent="detail.invoice_address" :disabled="true"></inputItem>
          <inputItem title="开户银行" :initialContent="detail.bank_name" :disabled="true"></inputItem>
          <inputItem title="银行帐号" :initialContent="detail.bank_number" :disabled="true"></inputItem>
          <inputItem v-if="detail.pre_time" title="预计回款时间" :initialContent="formatDate(detail.pre_time, 'yyyy-MM-dd')" :disabled="true"></inputItem>
          <inputItem title="发票种类" type="select" :initialContent="detail.invoice_tariff" :disabled="true"></inputItem>
          <inputItem title="发票合计" :initialContent="formatMoney(detail.invoice_price)" :disabled="true"></inputItem>
          <inputItem title="发票备注" :initialContent="detail.remark" :disabled="true"></inputItem>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="开票明细" label="开票明细">
          <div class="mobile-detail-list">
            <div class="list-item" v-for="(item, index) in items" :key="index">
              <div class="item-header">
                <div class="item-index">商品{{ index + 1 }}</div>
              </div>
              <div class="item-line">
                <div class="item-lable"> 商品名称 </div>
                <div class="item-value"> {{ item.goods_name }} </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 开票数量 </div>
                  <input class="input-right" v-model="item.num" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 开票金额 </div>
                  <input class="input-right" v-model="item.total" />
                </div>
              </div>
            </div>
          </div>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="审批流程" label="审批流程" v-if="flows && flows.flowLogs && flows.flowLogs.length > 0">
          <div class="mobile-detail-step">
            <Steps :current="flows.step" :status="flows.status" direction="vertical">
              <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
            </Steps>
          </div>
        </cube-scroll-nav-panel>
      </cube-scroll-nav>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
  </div>
</template>

<script>
import { GetInvoice, AuditInvoice } from "@/api";
import { invoiceState } from "@/utils/state.js";
import { formatDate, formatMoney } from "@/utils/format.js";
import { loadDetailAction, auditAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      items: [],
      flows: [],
      buttons: [],
    };
  },
  methods: {
    invoiceState: invoiceState,
    formatDate: formatDate,
    formatMoney: formatMoney,
    buttonClick: function (title) {
      switch (title) {
        case "审核通过":
          this.auditInvoice(true, "同意");
          break;
        case "审核拒绝":
          this.auditInvoice(false, "");
          break;
      }
    },
    auditInvoice: function (pass, memo) {
      auditAction(this, AuditInvoice, this.id, pass, memo);
    },
    dataRequest: function () {
      loadDetailAction(this, GetInvoice, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.items = data.list[0].items;
        this.flows = data.list[0].flows;
        this.buttons = data.list[0].buttons;
        this.$nextTick(() => { this.$refs.nav.refresh(); });
      });
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>